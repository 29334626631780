import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {    
     
    fetchVehicles(ctx, queryParams) { 
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + '/vehicles', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchVehicle(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_API_HOST + `/getVehicleById?vehicle_id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    }, 
    addVehicle(ctx, vehicleData) {
      var bodyFormData = new FormData();
      bodyFormData.append('vehicle_driver_id', vehicleData.vehicle_driver_id);
      bodyFormData.append('vehicle_plat_no', vehicleData.vehicle_plat_no);
      bodyFormData.append('vehicle_model', vehicleData.vehicle_model ?? "");
      bodyFormData.append('vehicle_status', vehicleData.vehicle_status );
      bodyFormData.append('vehicle_created_by', vehicleData.vehicle_created_by);  
      
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_API_HOST + '/vehicle',
          bodyFormData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateVehicle(ctx, vehicleData) {
      var bodyFormData = new FormData();
      bodyFormData.append('vehicle_driver_id', vehicleData.vehicle_driver_id);
      bodyFormData.append('vehicle_plat_no', vehicleData.vehicle_plat_no);
      bodyFormData.append('vehicle_model', vehicleData.vehicle_model ?? "");
      bodyFormData.append('vehicle_status', vehicleData.vehicle_status );
      bodyFormData.append('vehicle_created_by', vehicleData.vehicle_created_by);  
      bodyFormData.append('vehicle_id', vehicleData.vehicle_id);  
       
      
      return new Promise((resolve, reject) => {
        axios
          .put(process.env.VUE_APP_API_HOST + '/vehicle',
          bodyFormData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
